import React, { useEffect } from "react";
import Navbar from "../components/Navbar"
import { useState } from "react"
import GenreOverTime from "./GenreOverTime"
import Chart from "../components/Chart"
import { fetchAlbums, fetchAllAlbumsInGenre, fetchGenre } from "../api/index"
import Collapse from "@material-ui/core/Collapse"
import GenrePie from "../components/GenrePie";
import "../App.css"
import { Redirect } from "react-router";
import Dropdown from "./Dropdown";

const GenreSwitch = () => {
    const [genreObject, setGenreObject] = useState(null);
    const [genreCount, setGenreCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [albumArray, setAlbumArray] = useState(null);
    const [newGenre, setNewGenre] = useState("Rap");


    useEffect(() => {
        fetchAllAlbumsInGenre(newGenre).then((res) => {
            res.data[0] ? setGenreCount(res.data[0][`${newGenre.replace('|', '/')}`]): setGenreCount(-1);
            console.log(res.data[0])
        })
        fetchGenre(newGenre).then((res) => {
            console.log(res.data[0])
            if(res.data[0]){setGenreObject(res.data[0]);}
        })
        fetchAlbums().then((res) => {
            console.log(res.data[0])
            if(res.data[0]){setAlbumArray(res.data);}
        })
        console.log(`GenreSwitch: ${newGenre}`)
        //console.log(genre)
    }, [newGenre])


    return (
            (genreCount === -1) ? <Redirect to="/genres"/> :
            <div className="grid grid-cols-1 xl:grid-cols-1 fade-in p-0">
                <div className="flex flex-col xl:col-span-5">
                    <div className="flex flex-col items-start px-6">
                        <Dropdown type="genre" onChange={setNewGenre}/>
                        <div class="flex flex-col">
                            <Collapse collapsedHeight={220} in={open}>
                                {genreObject ? <p className="font-light mt-4 text-gray-200 text-justify" id="drop">{genreObject.desc}</p> : <p></p>}
                            </Collapse>
                            {
                                (genreObject ?
                                    (genreObject.desc.length > 1075 ?
                                        <button className=" text-gray-400 text-right inline font-bold mt-4 focus:outline-none" onClick={() => setOpen(!open)} variant="custom"
                                            aria-controls="drop"
                                            aria-expanded={open}>{open ? "Less" : "More"}
                                        </button> : <p></p>)
                                    :
                                    <p> Description not Available</p>
                                )
                            }
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
                        <div className="lg:col-span-7">
                            <GenreOverTime genre={newGenre} />
                        </div>
                        <div className="lg:col-span-5">
                            <h1 className="text-3xl px-6 mt-3 font-bold mb-3 text-white text-center">All Albums by Genre</h1>
                            {albumArray ? <GenrePie chartyear={albumArray} genreId={newGenre} type="allTime" /> : <p style={{ height: 300 }}></p>}
                        </div>

                    </div>
                    <p className="p-6 text-xl text-gray-300">Out of 5778 unique albums appearing on this chart, {newGenre} accounts for {genreCount} albums, or {Math.floor(genreCount / 5778 * 100)}% of unique albums</p>

                </div>

                {/* <div className="lg:col-span-2">
                    <Chart type="byGenre" genre={genre} chart_year={2020} />
                </div> */}
            </div>
    );
}

export default GenreSwitch;