import { fetchChartYear } from '../api/index';
import React, { useState, useEffect } from 'react';
import "../App.css"
import "simplebar/src/simplebar.css"
import Navbar from '../components/Navbar';
import Chart from '../components/Chart';

const Home = (props) => {
    const [chartYear, setChartYear] = useState(2020);
    const [chart, setChart] = useState([]);
    const [artistLetter, setArtistLetter] = useState("A");


    useEffect(() => {
        fetchChartYear(chartYear).then((res) => {
            console.log("Fetching chart data..")
            setChart(res.data)

            console.log(chartYear)
        })
    }, [chartYear])

    // Method for creating an entry component


    return (
        <div className="w-screen h-full flex flex-col">
            <Navbar />
            <div className="md:flex-grow md:overflow-scroll">
                <div className="flex xl:flex-row xl:justify-evenly md:px-8 md:gap-8 h-full w-screen justify-items-center flex-col mt-20 md:mt-0 ">
                    <div className="flex-shrink-0 overflow-scroll xl:h-full">
                        <Chart type="byYear" chart_year={chartYear} onChangeYear={setChartYear}/>
                    </div>
                    <div className="md:rounded-3xl w-full xl:max-w-5xl xl:overflow-scroll lg:mt-0 z-0 py-6 lg:py-16">
                        {React.cloneElement(props.children, {chart: chart, chartYear: chartYear, artistLetterChange: setArtistLetter})}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home