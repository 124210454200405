import React, { useState, useEffect } from "react";
import { fetchArtistsLetter, fetchAlbumsLetter } from '../api/index';
import Switch from '@material-ui/core/Switch'
import SearchResults from './SearchResults'

const Search = () => {
    const [query, setQuery] = useState('');
    const [matches, setMatches ] = useState([]);
    const [isArtistQuery, setArtistQuery] = useState(true);

    const changeQuery = (e) => {
        setQuery(e.target.value);
    }

    const show = (e) => {
        let searchBox = document.getElementsByClassName('search');
        window.onclick = (f) => {
            console.log(f.target)
            if (f.target.id === 'input' || f.target.id === "switch"){
                searchBox[0].classList.remove("hidden")
                searchBox[1].classList.remove("hidden")
            } else {
                searchBox[0].classList.add("hidden")
                searchBox[1].classList.add("hidden")
            }
        }
    }

    const dismiss = (e) => {
        console.log(e)
        e.target.value = ''
        
    }

    const handleCheck = (e) => {
        setArtistQuery(e.target.checked);
    }
    //focus on the div that pops up so that you can just add the onBlur to that component
    useEffect(() => {
        if(query !== ''){
            if (isArtistQuery){
                fetchArtistsLetter(query).then((res) => {
                    setMatches(res.data); 
                });
            } else {
                fetchAlbumsLetter(query).then((res) => {
                    setMatches(res.data);
                });
            }
        }
        else {
            setMatches([]);
        }
    }, [isArtistQuery, query])

    return(
        <div onBlur={show} onFocus={show} className="px-3 pt-2 xl:mt-0">
            <div className = "flex sm:flex-row justify-center w-full xl:w-80 py-2">
                <input
                    className="bg-search w-full h-9 rounded-md text-base md:text-lg focus:outline-none"
                    type="search"
                    autoComplete= "off"
                    id="input"
                    placeholder= {isArtistQuery ? " Search By Artist" : " Search By Album"}
                    onChange={changeQuery}
                    onBlur= {(e)=> dismiss(e)}>
                </input>
                <Switch
                    id="switch"
                    checked={isArtistQuery}
                    onChange={handleCheck}
                    color = 'default'> 
                </Switch>
                <SearchResults  results={matches} type={isArtistQuery? "artist" : "album"} />

            </div>
        </div>
    );
}

export default Search;