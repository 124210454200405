import "../App.css"
import {useEffect, useState} from 'react'
import { fetchAlbum } from "../api";
import { Link } from "react-router-dom";
import Image from './Image'

const AlbumModal = (props) => {
    const [AlbumTitle, setAlbumTitle] = useState(" ")
    const [AlbumImage, setAlbumImage] = useState(" ")

    useEffect(()=> {
        fetchAlbum(props.id).then((res)=> {
            setAlbumTitle(res.data[0]["title"]);
            setAlbumImage(res.data[0]["img"]);
        })
    }, [props.id])

    return (
        <div className="flex flex-col place-items-center">
            <img className="h-32 w-32 rounded-2xl" src={AlbumImage} alt=""/>
            {/* <Image source={AlbumImage} /> */}
            <p className='w-32 text-center py-2 font-bold'>
                <Link className="" to={"/albums/" + props.id}>{AlbumTitle}</Link>
            </p>
        </div>
    )
}

export default AlbumModal;