import { set } from "animejs"
import anime from "animejs/lib/anime.es"
import { useEffect } from "react"
import { useState } from "react"
import  arrow from '../img/up-arrow.svg'


const SpotifyWidget = ({spotifyID, onButtonTrigger}) => {
    const [open, setOpen] = useState(false)

    let animate2 = () => {
        anime({
        targets: ".spotify-wrapper",
        translateY: {
            value: open ? 0: -80,
        },
        loop: false,
        easing: 'easeOutQuint',
        duration: 800
      })
      anime({
        targets: ".V",
        rotate: {
            value: open ? -180 : 0, 
            easing: 'easeOutQuint',
            duration: 800
        },
        loop: false,
      })
      setOpen(!open)
      console.log(onButtonTrigger, open)
    }
    //check if spotify button got clicked
    useEffect(() => {
        console.log(onButtonTrigger, open)
        if(onButtonTrigger === true && open === false){
            animate2()
        }
    }, [onButtonTrigger, spotifyID])

    return (
        <div className="flex items-end w-full fixed inset-x-0 -bottom-20 z-10 flex-col spotify-wrapper" >
            <img className="V w-10 h-10 mr-2"  src={arrow} onClick={animate2}/>
            {spotifyID !== " " ? 
                (<iframe className="rounded-xl" id="iframe" src={spotifyID} width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="spotify album found"></iframe>)
                : (<iframe className="rounded-xl" id="iframe" src="" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="spotify album not found"></iframe>)
            }
        </div>    
        )
}

export default SpotifyWidget