import React, { useState, useEffect } from "react";
import { fetchArtistsLetter } from '../api/index';
import ArtistsThumbnail from "../components/ArtistsThumbnail";
import LoadingRing from "../components/LoadingRing"
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import "../App.css";


const Artists = ({letter, artistLetterChange}) => {
    const [letters, setLetters] = useState(letter);
    const [artists, setArtists] = useState([]);
    const [token, setToken] = useState('');
    const [expanded, setExpanded] = useState(-1);

    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    const colors = ['blue,','green','purple','red','pink', 'purple', 'yellow']



    //panel is the index number and newExpanded is a boolean (passed in from 'expanded')
    const handleChange = (panel) => (event, newExpanded)=> {
        console.log(`handleChange with panel ${panel} called`);
        let bit = document.getElementsByClassName("bit").item(panel).classList
        // if(panel !== expanded){
        //     bit.toggle("-rotate-45")
        // }
        // // // bit.toggle("scale-105")
        // // // bit.toggle("duration-500")
        // bit.toggle("rotate-180")

        setExpanded(newExpanded ? panel : -1)
        fetchArtistsLetter(alphabet[panel]).then((result) => {
            setArtists(result.data)
        })
    }


    return (
        <div className="p-6 xl:p-12 w-full mb-12">
            <h1 className="text-3xl md:text-6xl font-bold">Browse all artists</h1>
            {alphabet.map((temp_letter, index) => {
                return <div className="rounded-2xl">
                        
                    <Accordion expanded={expanded === index} style={{all:"inherit"}} TransitionProps={{ unmountOnExit: true }} onChange={handleChange(index)}>
                        <AccordionSummary className="" style={{all:"inherit"}}>
                            <div className="bit w-full h-20 hover:scale-105 opacity-80 hover:duration-500 transform transition md:h-24 lg:h-32 relative">
                                <div className={`absolute z-20 rounded-2xl h-full w-full mix-blend-color bg-gradient-to-r from-color3 via-color1 to-pink-800 `}>
                                </div>
                                <div className={`absolute z-10 rounded-2xl h-full w-full bg-gradient-to-r from-color3 to-transparent`}>
                                    <p className="text-4xl text-left xl:text-8xl rounded-2xl w-full h-full font-bold text-white p-3  ">{temp_letter}</p>
                                </div>
                                <iframe title="background" className={`absolute z-0 rounded-2xl h-full w-full hover:grayscale-0 filter grayscale bg-cover bg-${temp_letter.toLowerCase()} bg-right-top`}>
                                </iframe>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={`text-white`}>
                            <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 place-items-stretch fade-in">
                                {artists &&
                                    artists.map((artist, index) => {
                                        return <ArtistsThumbnail key={index} name={artist.name} id={artist._id} token={token} image={artist.image} />;
                                    })
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            })}
        </div>
    );
}

export default Artists;