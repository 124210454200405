import { useState, useEffect } from 'react';
import { fetchChartYearByGenre } from '../api/index';
import { Link } from 'react-router-dom'
import Label from './Label'
import ImageWithOverlay from './ImageWithOverlay';
import ArtistImage from './ArtistImage';

const TopGenreArtistsList = ({ data, year }) => {
    const [topGenreEntries, setTopGenreEntries] = useState([])

    useEffect(() => {
        fetchChartYearByGenre(data[0], year).then((res) => {
            setTopGenreEntries(res.data.slice(0, 4))
        })
    }, [data, year]) 

    return (
        <div className="flex flex-col items-center w-full">
            <h1 className= "text-3xl font-bold">{"Featured " + data[0] + " Artists"}</h1>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8 font-bold w-full">
                    {topGenreEntries.map((entry) => {
                        return (
                            <Link key={entry._id} title={entry["album"].artist} to={"/artist/" + entry["album"].artist.replace(' ', '%20')}>
                                <ArtistImage name={entry["album"].artist}/>
                            </Link>
                        )
                    })}
                </div>
        </div>
    )
}

export default TopGenreArtistsList
