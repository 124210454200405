import PropTypes from "prop-types"
import TopEntry from "./facts/TopEntry"
import TopGenre from "./facts/TopGenre"
import MostAwarded from "./facts/MostAwarded"
import OldestAlbum from "./OldestAlbum"

const Facts = ({ topEntry, data, chartYear }) => {

    return (
        <div className="flex flex-row mt-12 w-full ">
            <div className="grid grid-cols-1 gap-8 ">
                <MostAwarded 
                    mostAwarded={data[3]}
                    moreMostAwarded={data[4]}
                />
                <OldestAlbum
                    data={data[5]}
                    year={chartYear}
                />
            </div>
        </div>
    )
}

Facts.defaultProps = {
    topEntry: {},
}

Facts.propTypes = {
    topEntry: PropTypes.object,
}

export default Facts
