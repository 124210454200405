import React, { useState, memo } from 'react';
import Landing from "./pages/Landing"
import Home from "./pages/Home"
import Artists from "./pages/Artists"
import About from "./pages/About"
import GenreSwitch from "./components/GenreSwitch"
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import SpotifyWidget from './components/SpotifyWidget';
import HomeCard from './components/HomeCard';
import ArtistCard from './components/ArtistCard';
import AlbumCard from './components/AlbumCard';
import GenreCard from './components/GenreCard';


const App = () => {

    const [spotifyID, setSpotifyID] = useState(" ");
    const [buttonTrigger, setButtonTrigger] = useState(false)

    return (
        <Router>
            <div className="w-screen h-screen">
                <Switch className="w-screen h-screen">
                    <Route path="/" exact component={Landing} />
                    <Route path="/home" render= {(props)=> <Home><HomeCard></HomeCard></Home>}/>
                    <Route path="/artist/:artist_name" render= {(props)=> <Home><ArtistCard artist={props.match.params.artist_name}></ArtistCard></Home>}/>
                    <Route exact path="/artists" render= {(props)=> <Home><Artists></Artists></Home>}/>
                    <Route path="/about" component={About} />
                    <Route exact path="/genres" render= {(props)=> <Home><GenreSwitch></GenreSwitch></Home>} />
                    {/* <Route path="/genres/:genreId" render= {(props)=> <Home><GenreCard genre={props.match.params.genreId}></GenreCard></Home>}/> */}
                    <Route exact path="/albums/:albumId" render={(props) => <Home><AlbumCard albumID={props.match.params.albumId} onChangeAlbumId={setSpotifyID} onButtonTrigger={setButtonTrigger}/></Home>} />
                    <Route>
                        <Redirect to="/home" />
                    </Route>

                </Switch>
            </div>
            <SpotifyWidget spotifyID={spotifyID} onButtonTrigger={buttonTrigger}></SpotifyWidget>
        </Router>
    );
}

export default memo(App);
