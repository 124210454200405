import React, { useState } from "react"
import { useTransition, animated } from "react-spring"
import DropdownList from "./DropdownList"
import arrow from "../img/up-arrow.svg"

const Dropdown = ({type, year, onChange }) => {

    const [choice, setChoice] = useState(type === "year" ? 2020: "Rap" )


    const [open, setOpen] = useState(false)
    const transition = useTransition(open, {
        from: {
            display: 'flex', 
            // justify-content: "center",
            position: 'relative',
            top: type === "year" ? 35 : 60 ,
            // left: "10px",
            opacity: 0, 
            transform: 'translate3d(0px,-5px,0px)',
            pointerEvents: 'none',
            zIndex: "100"
        },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)', pointerEvents: 'auto' },
        leave: { opacity: 0, transform: 'translate3d(0,-5px,0)', pointerEvents: 'auto' }
    })

    const switchChoice = (year) => {
        setOpen(false)
        setChoice(year)
        onChange(year)
    }

    return (
        <div id="spesh" className="flex xl:ml-0 xl:px-0">
            <button className={`flex items-center space-x-2 transition duration-500 ease-in-out   xl:mr-0  py-1 mt-2  font-bold focus:outline-none `}
            onClick={() => setOpen(!open)}>
                <p className={`${type==='year'? 'text-2xl': 'text-4xl lg:text-7xl'} text-left`}>{choice}</p>
                <div className="transform rotate-180"><img className="w-4 h-4" src={arrow}/></div>
            </button>

            {transition((style, item) => item ? 
            <animated.div style={style} className={`${choice.length > 10 ? 'justify-end': 'justify-center'}`}>
                <DropdownList type={type} onSwitch={switchChoice} />
            </animated.div> : '')}

        </div>
    )
}



export default Dropdown
