import DropdownItem from "../components/DropdownItem"
import SimpleBarReact from "simplebar-react"
import "simplebar/src/simplebar.css"
import { useEffect, useState } from "react"
import { fetchGenres } from "../api"

const DropdownList = ({onSwitch, type}) => {


    const [genreList, setGenreList] = useState([]);
    const [listContent, setListContent] = useState([]);

    useEffect(() => {
        if(type==="year"){
            var years = []
            for (let y = 2020; y >= 1970; y--) {
                years.push(y)
            }
            setListContent(years)
        } else {
            fetchGenres().then((result) => {   
                console.log(result.data)
                setListContent(result.data)       
            }); 
        }
                             
    }, [])

    


    return (
        <div className="absolute mt-4 py-3 w-40 xl:w-44 bg-dark rounded-2xl shadow-xl font-bold text-center">
            <SimpleBarReact style={{ maxHeight: 200 }}>
                {/* <div className={`${type!=="year"? 'flex flex-col' : 'grid grid-cols-2'}`}> */}
                    {listContent.map((listItem) => {
                        return (
                            <DropdownItem
                                key={type === "year" ? listItem : listItem.name}
                                onSwitch={onSwitch}
                                year={type === "year" ? listItem : listItem.name}
                                type={type}
                            />
                        )
                    })}
                {/* </div> */}
            </SimpleBarReact>
        </div>
    )
}

export default DropdownList
