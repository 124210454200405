const GrammyComponent = () => {
  return (
    <svg
      className="w-6 mx-1"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "#A88F5E"}}
      viewBox="0 0 376.38 474.07">
      <ellipse
        className="cls-1"
        cx="143.29"
        cy="110.97"
        rx="107.31"
        ry="53.13"
        transform="translate(-59.26 158.74) rotate(-58.21)"/>
      <polygon
        className="cls-1"
        points="88.11 260.49 188.26 275.66 288.26 260.49 288.26 337.52 188.26 350.94 88.11 337.52 88.11 260.49"/>
      <polygon
        className="cls-1"
        points="54.85 347.44 0 447.22 188.19 474.06 376.38 447.81 320.94 347.44 188.26 365.53 54.85 347.44"/>
      <path
        className="cls-1"
        d="M285.65,264.28a4.77,4.77,0,0,1-3.87,4.69,323.54,323.54,0,0,1-61.14,5.83A323.94,323.94,0,0,1,159.53,269a4.8,4.8,0,0,1-3.9-4.7v-7.93a4.78,4.78,0,0,1,3.78-4.68,296.54,296.54,0,0,1,122.46,0,4.78,4.78,0,0,1,3.78,4.68Z"
        transform="translate(-32.73 -15.56)"/><path
        class="cls-1"
        d="M323,260c5.24-13.55,10.29-33.48,3.14-53.08-8-21.9-26.27-29.85-51.5-51.51a297.91,297.91,0,0,1-54-61.55,342.35,342.35,0,0,1-19.71,41.29A341.25,341.25,0,0,1,171.65,179c18.45.6,33.87,1.77,45.22,2.83,36.57,3.4,47.73,7.17,57.16,12.24a90.41,90.41,0,0,1,21,15.71l-64.38,9.42c-2.56-4.17-6.93-6.71-11.17-6.3-6,.57-11.28,7-10.5,14.78-.38,7.67,5.35,13.68,11.48,13.79,4.61.09,9.15-3.17,11.13-8.14l72.55-9.42A34.27,34.27,0,0,1,296.33,256Z"
        transform="translate(-32.73 -15.56)"/></svg>
  )
}

export default GrammyComponent;