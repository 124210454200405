import { useState, useEffect} from 'react'
import { fetchAlbum, fetchAudiodbAlbum, fetchSpotifyAlbum, fetchAlbumTracks } from '../api/index'
import { Redirect } from "react-router";
import ChartPosRecap from '../components/ChartPosRecap'
import "simplebar/src/simplebar.css"
import spot from '../img/spotifylogo.png'
import Collapse from "@material-ui/core/Collapse"
import GrammyRecap from "./GrammyRecap";
import SelectInput from '@material-ui/core/Select/SelectInput';


const AlbumCard = ({albumID, onChangeAlbumId, onButtonTrigger}) => {
    const [artistName, setArtistName] = useState(" ");
    const [albumName, setAlbumName] = useState(" ");
    const [genre, setGenre] = useState(" ");
    const [date, setDate] = useState(0);
    const [image, setImage] = useState(" ");
    const [awards, setAwards] = useState([]);
    const [chartPos, setChartPos] = useState([]);
    const [desc, setDesc] = useState(" ")
    const [spotifyTracks, setSpotifyTracks] = useState([])
    const [open, setOpen] = useState(false);
    const [spotifyID, setSpotifyID] = useState("");

    //let array = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    const handleChange = () => {
        const iframe = document.getElementById('iframe');
        const iFrameParent = iframe.parentElement;
        iframe.classList.add('fade-out')
        setTimeout(() => {
            iframe.remove();
            iFrameParent.append(iframe)
            iframe.classList.add('fade-in')
            onChangeAlbumId('https://open.spotify.com/embed/album/' + spotifyID)
            onButtonTrigger(true)
            }, 1000);
        }
    
    const fadeIn = () => {
        let container = document.getElementById('container')
        container.classList.remove('fade-in')
        container.classList.add('fade-in')
        //add opacity 0
        //sleep
        //add opacioty 100
    }

    useEffect(() => {
        //fadeIn()
        fetchAlbum(albumID).then((res) => {
            let object = res.data[0];
            setArtistName(object.artist);
            setAlbumName(object.title);
            setGenre(object.genre);
            setDate(object.release);
            setImage(object.img);
            setAwards(object.awards);
            setChartPos(object.chart_positions);

            fetchSpotifyAlbum(object.title, object.artist).then((res) => {
                let title = res.data[0].name
                if (title.toLowerCase() === object.title.toLowerCase()){
                    let spotifyAlbumID = res.data[0].id
                    console.log("spotify album id: ", spotifyAlbumID)
                    fetchAlbumTracks(spotifyAlbumID).then((res)=> {
                        setSpotifyTracks(res.data.body["items"]);
                        setSpotifyID(spotifyAlbumID);
                    }).catch((err)=> {
                        console.log(err)
                        setSpotifyTracks([])
                    })
                } else {
                    setSpotifyTracks([])
                    setSpotifyID("")
                }
            }).catch((err) => {
                console.log("No Spotify album found.");
                setSpotifyTracks([])
                setSpotifyID("")


            })

        }).catch((err) => {
            setAlbumName("No album found");
            setSpotifyTracks([])
            setSpotifyID("")
        })


    }, [albumID]);


    return (
        (albumName === "No album found") ? <Redirect to="/home" /> :
        <div id="container" className="grid grid-cols-7 p-12 gap-12 flex-row">
            <div className="col-span-7 lg:col-span-4 overflow-scroll">
                <div className="flex flex-col lg:flex-col gap-6">
                <img src={image} alt="" className="w-full rounded-2xl"/>
                    <div className="w-full flex flex-col lg:flex-row justify-between gap-6">
                        <div>
                            <h1 className="text-4xl font-bold inline-block">{albumName}</h1>
                            <h2 className="text-2xl text-gray-300">{artistName}</h2>
                        </div>
                      {
                          (spotifyTracks.length !== 0) &&                            
                            <div className="flex justify-end">
                                <button onClick={handleChange} className="focus:outline-none flex flex-col justify-center place-items-center w-36 bg-dark-1 px-6 py-2 rounded-xl">
                                    <p className="">Preview on </p>
                                    <img src={spot} className=" w-full" alt=""  />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="col-span-7 lg:col-span-3 flex flex-col gap-y-12">
                <div>
                    {
                        (spotifyTracks.length !== 0) &&                            
                        <h1 className='text-3xl font-bold mb-2'>Tracklist</h1>
                    }
                    {spotifyTracks.map((element, index) => {
                        return <p className="text-gray-300">{element.name}</p>
                    })}
                </div>
                <div className="flex flex-col justify-center">
                    <ChartPosRecap positions={chartPos}/>
                    <GrammyRecap awards= {awards} artist={artistName}/>
                </div>
            </div>
    </div>
    );
}

export default AlbumCard;

